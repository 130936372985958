@font-face {
  font-family: Montserrat;
  src: url(../fonts/Montserrat-Medium.ttf);
  display: swap;
}

/* Sass Decoration to assign color values*/
:root {
  --black--: #343a40;
  --purple--: #571f9c;
  --ash--: #f2f2f2;
  --white--: #ffffff;
  --blue--: #007bff;
  --deepblue--: #0056b3;
}

/* Margin and Padding removal*/
body {
  margin: 0;
  padding: 0;
  background-color: white;
  font-family: "Poppins" !important;
}

* {
  box-sizing: border-box;
  border: none;
  outline: none;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: none !important;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

h1 {
  font-family: "Montserrat" !important;
}

h2,
h3,
h4,
h5,
h6 {
  text-align: center;
  font-family: "Montserrat" !important;
}

.body {
  margin: 0px !important;
  padding: 0 !important;
  width: 100% !important;
}

nav {
  display: flex !important;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
  align-items: center;
}

.nav-link {
  color: #0d5be9;
  font-weight: 500;
}

@media all and (max-width: 400px) {
  .nav-link {
    margin-left: -35px;
  }
}

@media all and (min-width: 960px) {
  .close-menu {
    display: none;
  }

  .icon-menu {
    display: none !important;
  }
}

.nav-bar {
  padding-top: 20px;
}

.nav-list {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}

nav .icon-menu {
  display: none;
}

.theme-color {
  color: #007bfe;
}

.light-face {
  padding: 17px 45px;
  border-radius: 30px;
  border: 2px solid #007bfe;
  background-color: white !important;
  font-weight: 500;
  font-size: 13px !important;
}

a.light-face {
  color: #007bfe !important;
}

.nav-list li {
  margin-right: 10px;
}

@media screen and (min-width: 1024px) {
  .nav-list li {
    margin-right: 20px;
  }
}

.nav-list li a {
  color: #74899d;
  font-size: 15.5px;
}

.nav-list li a:hover {
  font-weight: 500;
}

.blue-face {
  padding: 17px 45px;
  border-radius: 30px;
  background-color: #007bfe;
  color: white !important;
  font-weight: 600;
  font-size: 13px !important;
}

.blue-face:hover {
  color: white;
  text-decoration: none;
}

.nav-list-menu {
  display: none;
}

@media all and (min-width: 960px) {
  .nav-list-menu {
    display: none;
  }
}

@media all and (max-width: 960px) {
  .nav-list-menu {
    /* display: block; */
    top: 100px;
    /* padding-top:500px; */
    /* display: block; */
    position: absolute;
    z-index: 999;
    background-color: white;
    width: 100%;
    margin-left: -25px;
  }

  .nav-bar {
    padding-top: 5px;
  }

  .nav-list {
    display: none;
  }

  nav .icon-menu {
    display: block;
    font-size: 25px;
    color: #007bfe;
    cursor: pointer;
  }

  .icon-menu {
    display: block !important;
    margin-right: 5px;
  }

  /* .close-menu{
    display: block;
    margin-right: 4px;
  } */
  .nav-list-menu li {
    padding-left: 40px;
    margin-right: 20px;
    margin-bottom: 35px;
  }

  .nav-list-menu li:last-child {
    margin-top: 50px;
  }

  .nav-list-menu:first-child {
    margin-top: 20px;
  }
}

#dropdown-basic-button {
  color: #007bff !important;
  /* font-size: 12px !important; */
  padding-left: 0px !important;
  padding-right: 0px !important;
}

#dropdown-basic-button:focus {
  box-shadow: none;
}

.dropdown-menu.show a {
  color: #007bff !important;
}

.dropdown-menu.show .coming-soon-in-nav {
  color: #007bff !important;
  font-size: 10px;
  opacity: 0.7;
}

@media all and (min-width: 960px) {
  #dropdown-basic-button {
    color: #74899d !important;
    font-size: 15.5px !important;
  }

  .dropdown-menu.show a {
    color: #333 !important;
  }

  .dropdown-menu.show .coming-soon-in-nav {
    color: #333 !important;
    opacity: 1;
  }
}
