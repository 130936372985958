@font-face {
  font-family: Montserrat;
  src: url(./fonts/Montserrat-Medium.ttf);
  display: swap;
}

/* Sass Decoration to assign color values*/
:root {
  --black--: #343a40;
  --purple--: #571f9c;
  --ash--: #f2f2f2;
  --white--: #ffffff;
  --blue--: #007bff;
  --deepblue--: #0056b3;
}
html {
  height: 100%;
}

#root {
  height: 100%;
}

/* Margin and Padding removal*/
body {
  margin: 0;
  padding: 0;
  background-color: white;
  font-family: "Poppins" !important;
  height: 100%;
  overflow-x: hidden;
}
* {
  box-sizing: border-box;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
  color: inherit;
}
a:hover {
  text-decoration: none !important;
}
ul {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}
h1 {
  font-family: "Montserrat" !important;
}
h2,
h3,
h4,
h5,
h6 {
  text-align: center;
  font-family: "Montserrat" !important;
}
.body {
  margin: 0px !important;
  padding: 0 !important;
  width: 100% !important;
}

@media all and (max-width: 965px) {
  .mmh {
    min-height: 320px;
    align-content: center;
    order: 1;
    margin-bottom: 36px;
    margin-top: 6px;
  }
}
@media all and (max-width: 975px) {
  .mmtop {
    margin-top: 30px;
  }
}
@media all and (max-width: 570px) {
  .customer-box {
    margin-bottom: 20px;
  }
}

@media all and (max-width: 400px) {
  .scanToTrigger {
    /* max-width: 600px !important; */
    padding-inline: 5px;
  }
}

.cards {
  width: 230px;
  min-height: 150px;
  border-radius: 15px;
  background-color: #f3f9ff;
  display: block;
  padding: 20px 20px;
  justify-content: center;
  text-align: center;
  margin-top: 15px;
}
@media all and (min-width: 710px) and (max-width: 1060px) {
  .cards {
    width: 346px;
  }
}

@media all and (min-width: 900px) and (max-width: 1290px) {
  .c2 {
    width: 300px !important;
  }
}
.c2 {
  background-color: white;
  width: 227px;
}
.cards b {
  font-size: 12.3px;
  display: block;
  margin-bottom: 3px;
}
.cards span {
  color: #74899d;
  font-size: 12px;
}
.cards > img {
  height: 150px;
  width: 100px;
  margin: auto;
}
header.row,
section .row {
  margin: 0px !important;
}
.card-container {
  display: flex;
  flex-flow: wrap;
  margin: auto;
  width: 100%;
  justify-content: space-around;
}
header h1 {
  font-weight: 900;
  line-height: 1.4;
}
.header-image {
  background-image: url("svg/Header-Background.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
@media screen and (max-width: 400px) {
  .whyChoose {
    padding-inline: 16px !important;
  }
}

.header-image img {
  width: 100%;
  height: auto;
  margin-bottom: 170px;
}
.section-image image {
  width: 100%;
  height: auto;
}
.customer-box b {
  font-size: 13px;
  display: block;
  margin-bottom: 6px;
  margin-top: 5px;
}
.no-margin {
  margin: 0px !important;
  padding: 30px;
  padding-bottom: 10px;
  background-color: #0f254d;
}
.customer-box > div:last-child {
  line-height: 0.95;
}
.customer-box span {
  color: #74899d;
  font-size: 12px;
}
section.main {
  background-color: #f3f9ff;
  min-height: 360px;
  width: 100%;
  margin-left: 1px;

  /* margin-top: 25px; */

  border-radius: 30px;
  align-items: center;
  padding: 35px 0px;
  justify-content: center;
}
section .customer-box {
  background-color: white;
  border-radius: 10px;
  display: grid;
  padding: 10px;
  min-height: 75px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.hh {
  height: 190px;
  display: grid;
  justify-content: start;
  margin-bottom: 35px;
}
.hh h2 {
  text-align: left;
}
.pix {
  background-color: transparent !important;
  margin-bottom: 12px;
}
.pix img {
  width: 45px;
  margin-right: 10px;
}
.footer-body {
  margin-bottom: 30px;
}
.footer-body span {
  color: #a8aeba;
  display: block;
  font-size: 11px;
  font-weight: 100;
  line-height: 1.2;
  margin: 15px 0px 15px 0px;
}
.footer-body > div {
  width: 110px;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  color: white;
}
footer ul li {
  color: white;
  margin-bottom: 15px;
}
footer ul li b {
  font-size: 14.5px;
}
footer ul li a {
  font-size: 11.5px;
  color: #a8aeba;
}
footer ul li a:hover {
  color: white !important;
}
footer form i {
  position: absolute;
  top: 18px;
  left: 5px;
}

.parent-grey-circle {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
}
.grey-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: block;
  background-color: #e6e6e6;
  margin: 5px;
  align-self: center;
}

/* .grey-circle:active{
  background-color: black;
} */
.blue-circle {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  display: block;
  margin: 5px;
  background-color: #1385ff;
}
footer input {
  background-color: transparent;
  border: 1.5px solid white;
  padding: 10px;
  border-radius: 7px;
  font-size: 12.5px;
  padding-left: 25px;
  color: white;
  width: 100%;
}
footer button {
  right: 5px;
  position: absolute;
  top: 4px;
  background-color: #007bfe;
  color: white;
  font-size: 11px;
  padding: 8px;
  border-radius: 5px;
}
@media all and (max-width: 970px) {
  .phone {
    width: 100%;
  }
}
.price-container {
  width: 270px;
  min-height: 550px;
  border-radius: 15px;
  padding: 36px 30px;
}
.price-container.h {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 15px;
}
.price-container.h:hover {
  background-color: #007bfe;
  color: white !important;
}
.form-toggler {
  align-items: center;
  justify-content: space-between;
  color: #73899d;
  font-weight: 200;
}
.form-toggler span {
  cursor: pointer;
  padding: 14px 30px;
}
.form-toggler .active {
  box-shadow: 0px 5.650793552398682px 11.301587104797363px 0px #0000002e;
  box-shadow: 0px 5.65079px 11.3016px rgba(0, 0, 0, 0.18);
  border-radius: 192.127px;
  background-color: white;
  color: #007bfe;
  font-weight: 700;
}
.price-info li {
  margin-bottom: 20px;
  font-size: 12px;
  position: relative;
}
.price-info li::before {
  background-image: url("");
}
.price .col-md-3 {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.price a {
  white-space: nowrap !important;
}
.faq,
.shark {
  justify-content: center;
  margin: auto !important;
}
.shark > div {
  border: 2px solid #e1e3e5;
  border-radius: 5px;
  margin-bottom: 15px;
  min-height: 70px;
  padding: 23px 40px;
}
.shark > div span {
  display: none;
  color: #73899d;
  font-size: 15px;
}
.shark > div:first-child span {
  display: block;
}
.journey {
  display: flex;
  background-color: #f3f9ff;
  border-radius: 20px;
  padding: 30px;
  margin-bottom: 30px;
}
.journeyVerify {
  display: flex;
  background-color: #f3f9ff;
  border-radius: 20px;
  padding: 30px;
  margin-bottom: 30px;
}
.journey2 {
  margin-top: 20px;
  display: grid;
  background-color: #f3f9ff;
  border-radius: 20px;
  padding: 30px;
  color: #007bfe;
  justify-content: center;
  justify-items: center;
  width: 204px;
}
.journey2 i {
  margin-bottom: 25px;
  font-size: 20px;
  cursor: pointer;
}
.journey2 a {
  font-weight: 400;
  font-size: 13px;
}
.journey .col-md-6 h2 {
  font-weight: 900;
  color: #007bfe;
  white-space: nowrap;
}
.journey .col-md-6 span {
  color: #73899d;
  font-size: 15px;
  font-weight: 400;
  display: block;
  line-height: 1.7;
}
.bigups {
  position: absolute;
  color: white;
  font-weight: 900;
  min-width: 350px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 120%;
}
.form-body {
  width: 100%;
}
.form-body2 {
  justify-content: space-between;
  display: flex;
}
.form-body2 input,
.form-body2 select {
  width: 48%;
  margin-bottom: 7px;
  margin-top: 7px;
  border-radius: 10px;
  padding: 14px 19px;
  font-size: 14px;
  color: #73899d;
}
.form-body input,
.form-body textarea,
.form-body select {
  width: 100%;
  margin-bottom: 7px;
  margin-top: 7px;
  border-radius: 10px;
  padding: 14px 19px;
  font-size: 14px;
  color: #73899d;
}
.form-body + button {
  display: flex;
  justify-items: center;
  margin: auto;
  padding: 17px 65px !important;
}
.iconer {
  width: 20px;
  margin-right: 5px;
}
.shark > div > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.shark .icon-minus,
.shark .icon-plus {
  cursor: pointer;
}
.shark .first .icon-plus {
  display: none;
}
.shark .first .icon-minus {
  display: block;
}
.shark .icon-minus {
  display: none;
}
.card-details {
  flex-flow: wrap;
  margin-bottom: 26px;
  display: flex;
  justify-content: space-evenly;
  margin-top: 15px;
}
@media all and (max-width: 600px) {
  .form-body2 {
    justify-content: normal;
    display: grid;
    width: 100% !important;
  }
  .form-body2 input,
  .form-body2 select {
    width: 100%;
  }
}
.verify-table tr {
  margin-bottom: 16px;
  display: block;
}
.verify-table tr td:first-child {
  font-size: 14px;
  color: #73899d;
  width: 100px;
}
.verify-table tr td:last-child {
  font-size: 14px;
}
.verify-links {
  width: 85%;
}
.verify-links > div {
  display: flex;
  justify-content: space-between;
}
.verify-links > div:first-child a {
  background-color: transparent !important;
}
.verify-links a {
  width: 172px !important;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
}
section .customer-box img {
  width: 37px;
  margin-left: 15px;
  margin-right: 5px;
}
.mmtop2 .customer-box {
  box-shadow: none !important;
}
.wrapper-demo {
  width: 48%;
  margin-top: 7px;
  margin-bottom: 7px;
  height: 45px;
}
.wrapper-demo2 {
  width: 100%;
}
.wrapper-dropdown-3.active .dropdown {
  opacity: 1;
  pointer-events: auto;
}
.wrapper-dropdown-3 .dropdown {
  position: absolute;
  top: 120%;
  left: 0;
  right: 0;
  z-index: 10;
  background: rgb(255, 255, 255);
  border-radius: inherit;
  border: 1px solid rgba(0, 0, 0, 0.17);
  box-shadow: 0 0 5px rgb(0 0 0 / 10%);
  font-weight: normal;
  -webkit-transition: all 0.5s ease-in;
  -moz-transition: all 0.5s ease-in;
  -ms-transition: all 0.5s ease-in;
  -o-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
  list-style: none;
  opacity: 0;
  pointer-events: none;
}
.wrapper-dropdown-3 {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  background: #fff;
  border-radius: 7px;
  cursor: pointer;
  outline: none;
  font-weight: bold;
  color: #8aa8bd;
}
.wrapper-dropdown-3:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -3px;
  border-width: 6px 6px 0 6px;
  border-style: solid;
  border-color: #8aa8bd transparent;
}
.wrapper-dropdown-3 .dropdown:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  bottom: 100%;
  right: 13px;
  border-width: 0 8px 8px 8px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.1) transparent;
}
.wrapper-dropdown-3 .dropdown li a {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #080808;
  cursor: pointer;
  border-bottom: 1px solid #e6e8ea;
  box-shadow: inset 0 1px 0 rgb(255 255 255);
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.wrapper-dropdown-3 .dropdown li:first-of-type a {
  border-radius: 7px 7px 0 0;
}
.wrapper-dropdown-3.active .dropdown {
  opacity: 1;
  pointer-events: auto;
}
.wrapper-dropdown-3 .dropdown li:hover a {
  background: #f3f8f8;
  cursor: pointer;
}

.child-container1 {
  margin: 4px;
  /* width: 23%; */
  grid-column-start: 1;
  height: 630px;
}
.child-container2 {
  margin: 4px;
  /* width: 23%; */
  grid-column-start: 2;
  height: 630px;
}
.child-container3 {
  margin: 4px;
  /* width: 23%; */
  grid-column-start: 3;
  height: 630px;
}
.child-container4 {
  margin: 4px;
  /* width: 23%; */
  grid-column-start: 4;
  height: 630px;
}
@media all and (max-width: 1190px) {
  .child-container1 {
    grid-column-start: 1;
  }
  .child-container2 {
    grid-column-start: 2;
  }
  .child-container3 {
    grid-column-start: 1;
  }
  .child-container4 {
    grid-column-start: 2;
  }
}

@media all and (max-width: 650px) {
  .child-container1 {
    grid-column-start: 1;
  }
  .child-container2 {
    grid-column-start: 1;
  }
  .child-container3 {
    grid-column-start: 1;
  }
  .child-container4 {
    grid-column-start: 1;
  }
}

@media all and (min-height: 1300px) {
  .col-md-6 {
    max-width: 100% !important;
  }
  .journeyVerify {
    display: block !important;
    margin-top: 140px !important;
    margin-bottom: auto !important;
  }
}

.our-products {
  width: 100%;
  margin: 30px 0px;
}
.our-products-btns-container {
  display: flex;
  gap: 30px;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
}
.our-products-btns-container .each-btn {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.our-products-btns-container .each-btn .soon {
  font-weight: 800;
}
.our-products .heading {
  text-align: center;
}
