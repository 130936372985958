
* {
  margin: 0;
  padding: 0;
}


.slider {
  
 
  display: flex;
justify-content:center;
 max-width: 600px;
  height:400px;
  margin:15px 0px;
  
}

@media all and (max-width:400px){
.slider{
width: 345px;
/* height: 220px; */
   height: 223px;
   margin-left: 5px;
   margin-right: 3px;
}

} 

.image {
  
  height: 100%;
  width:100%;
object-fit: fill;
border-radius: 30px;
z-index: -1;

}

.right-arrow {

  font-size: 50px;
  color:black;
  z-index: 10;
  cursor: pointer;
  user-select: none;
  align-self: center;
  cursor: pointer;
}

.left-arrow {
  align-self: center;
  font-size: 50px;
  cursor: pointer;
  color: black;
  z-index: 10;
  cursor: pointer;
  user-select: none;

}



.slide5{
  margin-right: -39px;
  border-radius:30px;
  height:260px;
  align-self: center;
  z-index: 0;
  opacity: 1;
  transition-duration: 2s;
  width: 133px;
}
/* .slide1>img{
  height: 100%; 
  object-fit:none !important;
} */


.slide {

 z-index: 6;
  margin-right: -25px;
  height:359px;
  width: 171px;
  border-radius: 30px;
  align-self: center;
  opacity: 1;
  transition-duration: 2s;
 
  }
  .slide>img:active{
    transition-duration: 2s;
    transform: translate3d(60%,0,-200px);
  }

 
 
  @media only screen and (max-width: 600px) {

    .slide>img {
      width:100%;
      object-fit: fill;
    
      
   }

    .slide5>img {
      width:100%;
      object-fit: fill;
      /* height: 250px; */
   }
  
    .slide1>img {
       width:100%;
       object-fit: fill;
       height:100%;
       
    }
    .slide3>img {
      width:100%;
      object-fit: fill;
   }
    
   .slide2>img {
    width:100%;
    object-fit: fill;
 }

   
  }

  @media all and (max-width:400px){
    .slide1{
      width: 210px !important;
     
      height: 260px !important;
    }
    .slide{
      width: 183px !important;
      height: 226px !important;
    }
    .slide3{
      width: 183px !important;
      height: 226px !important;
    }
    .slide5{
      height: 170px  !important;

    }
    .slide2{
      height: 170px  !important;
    }
  }

  .grey-circle-active{
    align-self: center;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: block;
    background-color:#007bfe;
    margin: 5px;
    padding: 7px
  }

.slide1 {
  margin-left: -42px;
  border:5px solid black;
  z-index: 14;
  height: 412px;
  border-radius: 30px;
  align-self: center;
  opacity: 1;
  transition-duration: 2s;
  background-color: white;
  width: 240px;
 
   
}
.slide1>img:active{
  transition-duration: 2s;
  transform: translate3d(46%,0,-100px);
}
.slide3{
  margin-right: -31px;
  margin-left: -59px;
  z-index: 8;
  height:359px;
  border-radius: 30px;
  align-self: center;
  width: 171px;
  opacity: 1;
  transition-duration: 2s;
  
}
.slide3>img:active{
  transition-duration: 2s;
  transform: translate3d(60%,0,-200px);
}

.slide2{
  z-index: 0;
  border-radius: 30px;
  height:260px;
  align-self: center;
  opacity: 1;
  transition-duration: 2s;
  width: 133px;
}





.visuallyhidden {
  position: absolute;
  z-index: -1;
  right: 0;
  opacity: 0;
}



/* .container {
  overflow: hidden;
  padding: 20px;
  margin-top: 2em;
  width: 100%;
 
} */


.card-carousel {
--card-width: 21%;
/* --card-max-width: 280px; */
--card-height: 400px;
--carousel-width: 1140px;
z-index: 1;
position: relative;
margin: 0 auto;
width: 100%;
height: var(--card-height);
min-width: var(--carousel-width);
transition: filter .3s ease;
}

/* @media screen and (max-width: 640px) {
  .card-carousel {
    margin-left: calc((100vw - var(--carousel-min-width) - 40px) / 2)
  }
} */

.card-carousel.smooth-return {
  transition: all .2s ease;
}

.card-carousel .card {
  background: whitesmoke;
  width: var(--card-width);
  max-width: var(--card-max-width);
  text-align: center;
  
  min-width: 250px;
  height: var(--card-height);
  position: absolute;
  margin: 0 auto;
  color: rgba(0,0,0,.5);
  transition: inherit;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.3);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.3);
  box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.3);
  border-radius: 30px;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card.highlight {
  filter: brightness(1);
  border:5px solid black;

}



.image-container::after {
  content: "";
  display: block;
  width: 120%;
  height: 120%;
  border: solid 3px rgba(0,0,0,.1);
  border-radius: 100%;
  position: absolute;
  top: calc(-10% - 3px);
  left: calc(-10% - 3px);
}

@media all and (max-width: 400px){
  .card-carousel{
    min-width: 330px; 
     }
     .image{
       max-width: 100%;
     }
}
@media all and (min-width:900px) and (max-width: 1199px){
  .card-carousel{
    --carousel-width: 935px;
    --card-width: 21%;
     }

   .slider{
     max-width: 998px;
   }
}
@media all and (min-width:800px) and (max-width: 900px){
  .card-carousel{
    --carousel-width: 835px;
    --card-width: 21%;
     }
}

@media all and (min-width:650px) and (max-width: 800px){
  .card-carousel{
    --carousel-width: 680px;
    --card-width: 21%;
     }
}

@media all and (min-width:500px) and (max-width: 650px){
  .card-carousel{
    --carousel-width: 450px;
    --card-width: 21%;
     }
}
@media all and (min-width:350px) and (max-width: 500px){
  .card-carousel{
    --carousel-width: 402px;
    --card-width: 21%;
    --card-height: 297px;
    margin-left: 20px;
     }
     .card-carousel .card{
      min-width: 177px;
     }
     .slider{
       height:300px;
     }
}
